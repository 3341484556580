import { Link } from 'gatsby';
import React from 'react';
import Button from './Button';

import './LinkButton.css';

const LinkButton = props => {
	return (
		<Link to={props.to}>
			<Button id={props.id} className={'link ' + (props.className ? props.className : '')} onClick={props.onClick} style={props.style}>
				{props.children}
			</Button>
		</Link>
	);
};

export default LinkButton;
