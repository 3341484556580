import React, { useEffect } from 'react';

import LinkButton from '../Button/LinkButton';

import '../Footer2020/Footer2020.css';
import '../Footer2020/Footer2020.scss';

var TimelineMax;
var detect = require('is-client');
if (detect())
	TimelineMax = require('gsap').TimelineMax;

const logoStar = require('../../images/logo-star.png');
const iconIg = require('../../images/footer-icon.svg');
const iconFb = require('../../images/footer-icon2.svg');
const iconLd = require('../../images/footer-icon3.svg');
const footerBgTop = require('../../images/footer-bg-top.png');
const footerBgBottom = require('../../images/footer-2020-cut-2.png');
const navicella = require('../../images/navicella.svg');
const footerMobileImg = require('../../images/back_footer_mob.svg');
const footerBgTopMobile = require('../../images/footer-bg-top-mobile.png');


var tl, tl2, tl3;

const Footer = props => {

	useEffect(() => {
		if (TimelineMax) {
			tl = new TimelineMax({
				paused: true,
			});
			tl.to('.navicella-top', 0.8, { x: '-24em', y: '6em', rotate: '36deg', scale: 1.4 });
			tl2 = new TimelineMax({
				paused: true,
			});
			tl2.to('.button-contact-expand', 0.5, { width: '8em' });
			tl3 = new TimelineMax({
				paused: true,
			});
			tl3.to('.label-button', 0.3, { opacity: 1 });
		}
	}, []);

	function moveShip() {
		if (tl && tl2 && tl3) {
			tl.play();
			tl2.play();
			tl3.play();
		}
	}
	function resetShip() {
		if (tl && tl2 && tl3) {
			tl.reverse();
			tl2.reverse();
			tl3.reverse();
		}
	}

	return (
		<footer className="at-footer at-footer-2020">
			<div onMouseEnter={() => moveShip()} onMouseLeave={() => resetShip()} className="project-proposition">
				<h2>
					Hai un <span className="orange-color">progetto</span> in mente <span className="orange-color">?</span>
					<br />
					<div className="button-parliamone">
						<a href="/contatti/">
							<strong>Parliamone.</strong>
						</a>

					</div>

				</h2>
				{/* 
				<div className={`button-contact-expand`}>
					<label className="label-button">Parliamone </label>
				</div>
				*/}
			</div>
			<div className="footer-container">
				<img src={navicella} className="navicella-top"></img>
				<img src={footerBgTop} className="footer-background-top" />
				<img src={footerBgBottom} className="footer-background-bottom" />
				<div className="container">
				<img src={footerMobileImg} className="footer-background-mobile"></img>
				<div className="container-img-mob">
				<div onMouseEnter={() => moveShip()} onMouseLeave={() => resetShip()} className="project-proposition-mobile">
				<h2>
					Hai un <span className="orange-color">progetto</span> in mente <span className="orange-color">?</span>
					<br />
					<div className="button-parliamone">
						<a href="#">
							<strong>Parliamone.</strong>
						</a>

					</div>

				</h2>
				{/* 
				<div className={`button-contact-expand`}>
					<label className="label-button">Parliamone </label>
				</div>
				*/}
			</div>
				<img src={footerBgTopMobile} className="footer-background-top-mobile"></img>
				</div>

					<div className="row ">
						<div className="col-12 col-lg-3 first-col desktop">
							<div className="copyright">
								<img className="logo-ats" src={logoStar} alt="Logo" />
							</div>

							<div className="copyright">
							<div className="menu__socials__footer"><a id="instagram" href="https://www.instagram.com/alfatauristudio" target="blank"><svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path></svg><span>Instagram</span></a><a id="linkedin" href="https://www.linkedin.com/company/alfatauri-studio" target="blank"><svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"></path></svg><span>Linkedin</span></a></div>

							</div>

							<div className="copyright">
								<span className="copyText">
									&copy; All rights reserved 2021 <br /> ALFATAURI STUDIO - P.IVA 03666510791{' '} <br /> <a style={{ color: "white" }} href="https://www.google.com/maps/place/Alfatauri+Studio/@41.9092732,12.5368397,17z/data=!3m1!4b1!4m5!3m4!1s0x132f63c95103fe59:0x536e7ec5b23acc8f!8m2!3d41.9092692!4d12.5390284" target="_blank"> Via Renato Simoni 30<br></br>00157 Roma </a>
								</span>
							</div>
						</div>
						<div className="col-12 col-lg-3 mob-top">
							<div className="footer-menu">
								<ul>
									<LinkButton //className={l.active ? 'active' : ''}
										key="link-to-0"
										href="#"
										class="title-link"
										to="/home"
									>
										Home
									</LinkButton>
									<li>
										<LinkButton //className={l.active ? 'active' : ''}
											key="link-to-0"
											href="#"
											to="/home"
										>
											I nostri progetti
										</LinkButton>
									</li>
									<li>
										<LinkButton //className={l.active ? 'active' : ''}
											key="link-to-0"
											href="#"
											to="/home"
										>
											Elevator Pitch
										</LinkButton>
									</li>
								</ul>
							</div>
						</div>

						<div className="col-12 col-lg-3">
							<div className="footer-menu">
								<ul>
									<LinkButton //className={l.active ? 'active' : ''}
										key="link-to-1"
										href="#"
										class="title-link"
										to="/servizi"
									>
										Servizi
									</LinkButton>

									<li>
										<LinkButton //className={l.active ? 'active' : ''}
											key="link-to-0"
											to="/servizi"
										>
											I nostri servizi
										</LinkButton>
									</li>

									<li>
										<LinkButton //className={l.active ? 'active' : ''}
											key="link-to-0"
											to="/servizi"
										>
											Esperienze
										</LinkButton>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-12 col-lg-3">
							<div className="footer-menu">
								<ul>
									<LinkButton //className={l.active ? 'active' : ''}
										key="link-to-2"
										href="#"
										class="title-link"
										to="/contatti"
									>
										Contatti
									</LinkButton>
									<li>
										<LinkButton //className={l.active ? 'active' : ''}
											key="link-to-0"
											to="/contatti"
										>
											Contatti
										</LinkButton>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-12 col-lg-3 first-col mobile socials">
							<div className="copyright">
								<img className="logo-ats" src={logoStar} alt="Logo" />
							</div>

							<div className="copyright">
								<div className="menu__socials__footer"><a id="instagram" href="ttps://www.instagram.com/alfatauri-studio" target="blank"><svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path></svg><span>Instagram</span></a><a id="linkedin" href="https://www.linkedin.com/company/alfatauri-studio" target="blank"><svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"></path></svg><span>Linkedin</span></a></div>
							</div>

							<div className="copyright">
								<span className="copyText">
									&copy; All rights reserved 2021 <br /> ALFATAURI STUDIO - P.IVA 03666510791{' '} <br /> <a style={{ color: "white" }} href="https://www.google.com/maps/place/Alfatauri+Studio/@41.9092732,12.5368397,17z/data=!3m1!4b1!4m5!3m4!1s0x132f63c95103fe59:0x536e7ec5b23acc8f!8m2!3d41.9092692!4d12.5390284" target="_blank"> Via Renato Simoni 30 <br></br>00157 Roma </a>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
